/* 全ページ共通 */

@use "../mixin" as *; //変数読み込み

* {
  font-size: $main-font-size;
  color: $main-text-color;
  font-family: 'Jost', sans-serif;
  font-family: 'Noto Sans', 'Noto Sans JP', sans-serif;
  font-weight: 500;
}

h1 {
  font-size: $large6-font-size;
}

h2 {
  font-size: $large5-font-size;
  text-align: center;
  font-weight: 700;
}

img {
  max-width: 100%;
}

section {
  padding-top: 80px;
  padding-bottom: 100px;
}

.container {
  width: min(100%, $container-width);
  margin-right: auto;
  margin-left: auto;
}


.c_heading-text {}

.c_heading-icon-area {
  display: flex;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 25px;
}

.c_heading-icon {
  width: 120px;
  height: 5px;
  display: inline-block;
  background: -moz-linear-gradient(left, $sub3-text-color 0%, $sub3-text-color 50%, $strong-background-color 50%, $strong-background-color 100%);
  background: -webkit-linear-gradient(left, $sub3-text-color 0%, $sub3-text-color 50%, $strong-background-color 50%, $strong-background-color 100%);
  background: linear-gradient(to right, $sub3-text-color 0%, $sub3-text-color 50%, $strong-background-color 50%, $strong-background-color 100%);
}

.c_subheading-text {
  text-align: center;
  font-size: $large2-font-size;
  font-weight: 700;
  line-height: 1.6em;
}


.c_popup-block {
  background-color: rgba(0, 0, 0, .5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  display: none;

  .inner-box {
    display: grid;
    width: 100%;
    max-width: 1200px;
    background: #fff;
    margin: 100px auto 200px;
    position: relative;
    border-radius: 20px;
    position: relative;
  }

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #fff;
    width: 30px;
    aspect-ratio: 1/1;
    line-height: 30px;
    background-color: #d9d9d9;
    border-radius: 30px;
    text-align: center;
  }

  .main-area {
    display: grid;
    gap: 50px;
    padding-top: 60px;
  }

  .box {
    background-color: #fff;
    padding: 30px 55px 30px 35px;
    display: flex;
    column-gap: 50px;
    border-radius: 20px;
    max-height: calc(100vh - 70px - 120px);
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .img-pc {
    width: 380px;
    height: 520px;
  }

  .img-sp {
    display: none;
  }

  .company {
    font-size: $large-font-size;
    font-weight: 700;
  }

  .heading2-text {
    padding-top: 5px;
    padding-bottom: 22px;
    font-size: $large-font-size;
    font-weight: 700;
    border-bottom: 1px solid #DFDFDF;
  }

  .text-large {
    font-size: 26px;
    font-weight: 700;
  }

  .english {
    padding-left: 20px;
    color: $strong-background-color;
    font-size: $large4-font-size;
    font-family: 'Caveat', cursive;
    font-weight: 700;
  }

  .description {
    padding-top: 24px;
    line-height: 28px;
  }

  .link {
    color: $link-text-color;
    text-decoration: underline;
  }

}

.c_tb-emerge {
  display: none;
}

.c_sp-emerge {
  display: none;
}

.c_tb-only-disapear {
  display: block;
}