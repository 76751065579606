/* Post Page_PC */

@use "../mixin" as *; //変数読み込み

.postPage__main__section {
    padding-top: 180px;
    padding-bottom: 90px;
    font-family: 'Jost', sans-serif;
    font-family: 'Noto Sans', 'Noto Sans JP', sans-serif;
    background-color: $main-background-color;

    .heading-area {
        padding-bottom: 30px;
    }

    .main-heading-text {
        font-size: 26px;
    }

    .main-area h3 {
        padding: 17px 22px;
        font-size: $large2-font-size;
        color: #fff;
        font-weight: bold;
        strong {
            color: #fff;
            font-weight: bold;
        }
    }

    .main-area p {
        padding: 17px 22px 24px;
        font-size: $large-font-size;
    }
}
