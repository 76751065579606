/* Caseページ */

@use "../mixin" as *; //変数読み込み


/* 受講事例 */
.case-page {
  padding-top: $header-height_pc;

  .main__section {
    background-color: $main-background-color;

    .casePage-heading-icon-area {
      margin-bottom: 35px;
    }

    .main-area {
      background-color: #fff;
      padding: 60px 70px 70px;
      border-radius: 20px;
    }

    .sub-heading-text {
      text-align: center;
      color: $button-background-color;
      font-size: $large5-font-size;
      line-height: 45px;
    }

    .center-wrap {
      display: grid;
      grid-template-columns: 380px 1fr;
      gap: 40px;
      padding-top: 48px;
    }

    .item-box {}

    .top-text {
      display: flex;
      padding-bottom: 16px;
    }

    .case-text {
      font-size: $large5-font-size;
      color: $strong-background-color;
      font-family: 'Caveat', cursive;
    }

    .image-portion {
      display: flex;
      flex-direction: column;
    }

    .number {
      font-size: 55px;
      color: $button-background-color;
      margin-left: 9px;

      &--sp {
        display: none;
      }
    }

    .img-pc {
      width: 100%;
      height: 239px;
      object-fit: cover;
      object-position: 50% 0%;
    }

    .img-sp {
      display: none;
      width: 100%;
    }

    .text-portion {
      position: relative;
      background-color: #fff;
    }

    .top-text {
      padding-bottom: 20px;
      display: flex;
      align-items: end;
    }

    .text-block {}

    .name {
      font-size: $large4-font-size;
      font-weight: bold;
      margin-left: 12px;
    }

    .career {
      padding-top: 21px;
      padding-bottom: 20px;
      border-bottom: 1px solid #dfdfdf;
      text-align: center;
      font-weight: bold;
      font-size: $large2-font-size;

      &--strong {
        color: $button-background-color;
        font-size: $large2-font-size;
        font-weight: bold;
      }
    }

    .description {
      padding-top: 20px;
      display: flex;
      justify-content: center;
    }

    .description-text {
      padding-top: 20px;
      line-height: 24px;
      font-size: $main-font-size;
    }

    .button-block {
      position: relative;
    }

    .read-button {
      background-color: $button-background-color;
      color: #fff;
      border-radius: 40px;
      padding: 6px 21px;
    }

    .comingsoon {
      padding-top: 60px;
      text-align: center;
    }

    .img {
      width: 211px;
    }

    .questions-box {
      display: grid;
      gap: 30px;
    }

    .question-item {
      display: grid;
      gap: 20px;
    }

    .question-portion {
      display: flex;
      gap: 7px;
      align-items: center;
      background-color: #FFE39A;
      border-radius: 30px;
      padding: 7px 22px;
    }

    .q-icon {
      color: #fff;
      font-size: $large-font-size;
    }

    .question-text {
      color: $button-background-color;
      font-size: $large-font-size;
    }

    .answer-portion {
      display: flex;
      gap: 10px;
      padding-left: 15px;
    }

    .a-icon {
      background-color: $button-background-color;
      color: #fff;
      font-weight: bold;
      line-height: 25px;
      width: 25px;
      border-radius: 25px;
      text-align: center;
    }

    .answer-text {
      font-size: $small-font-size;
      line-height: 24px;
    }
  }

}