/* scss変数設定 */

/* 色 */
$main-color: #F19B12;

$title-color: #30C1FF;

$sub-color: #FFEC01;

$main-icon-color: #FFD362;

$sub-icon-color: #036EB7;

$sub2-icon-color: #FF9458;

$main-background-color:#F8FDFF;
// rgba(48, 193, 255, 0.05)

$sub-background-color: rgba(255, 211, 98, 0.10);

$strong-background-color :#FFD362;

$button-background-color: #FF833E;

$footer-background-color: #FAFAFA;

$main-text-color: #333;

$sub-text-color: #F29C14;

$sub2-text-color: rgba(255, 255, 255, 0.92);

$sub3-text-color: #30C1FF;

$link-text-color: #036EB7;

$main-button-color: #0F6CA9;

$table-border-color: #E8C636;

/* フォントサイズ */
$main-font-size: 16px;

$large-font-size: 18px;

$large2-font-size: 20px;

$large3-font-size: 22px;

$large4-font-size: 24px;

$large5-font-size: 30px;

$large6-font-size: 32px;

$large7-font-size: 38px;

$small-font-size: 14px;

$small2-font-size: 12px;

/* コンテナサイズ */

$container-width: 1200px;

/* ブレークポイント */

$tablet-breakpoint: 1280px;

$smartphone-breakpoint: 600px;

/* ヘッダーサイズ */
$header-height_pc: 90px;

$header-height_tb: 60px;

$header-height_sp: 45px;