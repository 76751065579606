/* Header_TB */

@use "../mixin" as *; //変数読み込み

@media (width < 1360px) {

  .header {
    height: $header-height_tb;

    .logo-img {
      margin-top: 10px;
      margin-bottom: 10px;
      // width: min(380px, 55vw);
      max-height: 40px;
      width: auto;
    }

    .inner {
      height: 100%;
      padding-right: 0;
      padding-left: 9px;
    }

    .menu-area {
      height: 100%;
      flex-direction: row;
    }

    .contact-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 15px;
      height: 100%;
      border-radius: 0;
      font-size: $main-font-size;
    }

    .contact-link {
      line-height: $header-height_tb;
    }

    .menu-area {
      flex-direction: row;
      gap: 0;
    }

    .global-navigation__list {
      gap: 30px;
      flex-direction: column;
    }

    .menu-link {
      font-size: 17px;
    }

    .humberger {
      display: block;
      margin-left: auto;
      position: relative;
      z-index: 80;
      border: none;
      background-color: transparent;
      width: $header-height_tb;
      height: $header-height_tb;
      background-color: #dfdfdf;
    }

    .humberger.-active .humberger__line {
      background-color: transparent;
    }

    .humberger.-active .humberger__line::before {
      top: 0;
      transform: rotate(45deg);
    }

    .humberger.-active .humberger__line::after {
      top: 0;
      transform: rotate(-45deg);
    }

    .humberger.-active .humberger__text::before {
      content: '閉じる';
    }

    .humberger__line {
      display: block;
      height: 2px;
      position: absolute;
      top: calc(23 / 45 *$header-height_tb);
      left: 50%;
      transform: translateX(-50%);
      width: calc(28/45 * $header-height_tb);
      background-color: #fff;
      transition: 0.4s;
    }

    .humberger__line:before,
    .humberger__line:after {
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
      background-color: #fff;
      transition: inherit;
    }

    .humberger__line:before {
      top: calc(-10/45*$header-height_tb);
    }

    .humberger__line:after {
      top: calc(10/45*$header-height_tb);
    }

    .humberger__text {
      position: absolute;
      bottom: calc(8/45*$header-height_tb);
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
    }

    .humberger__text::before {
      content: "メニュー";
      text-align: center;
      color: #fff;
      font-size: 10px;
      font-weight: 900;
    }

    .header__nav-area {
      position: fixed;
      top: 0;
      left: -100%;
      z-index: 9;
      width: 100%;
      visibility: hidden;
      margin-top: $header-height_tb;
      background-color: #fff;
      transition: 0.4s;
    }

    .header__nav-area.-active {
      left: 0;
      visibility: visible;
    }

    .global-navigation {
      padding-top: 35px;
      padding-bottom: 35px;
    }

    .global-navigation__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #172e59;
      font-weight: 900;
      transition: color 0.4s;
      font-size: 0.875rem;
    }

    .global-navigation__link.-accordion {
      position: relative;
      background: none;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      padding: 0;
    }

    .global-navigation__link.-accordion::after {
      content: '';
      display: block;
      height: 12px;
      position: absolute;
      top: 50%;
      right: 5px;
      width: 2px;
      background-color: #ed3242;
      transform: translateY(-50%);
      transition: transform 0.4s;
    }

    .global-navigation__link.-accordion::before {
      content: '';
      display: block;
      height: 2px;
      position: absolute;
      top: 50%;
      right: 0;
      width: 12px;
      background-color: #ed3242;
      transform: translateY(-50%);

    }

    .global-navigation__link.-active::after {
      transform: translateY(-50%) rotate(-90deg);
    }

    .accordion {
      height: 0;
      // overflow: hidden;
      visibility: hidden;
      transition: 0.4s;
    }

    .accordion.-active {
      height: auto;
      padding-top: 30px;
      visibility: visible;
    }

    .accordion__list li {
      font-size: 0.75rem;
    }

    .accordion__list li+li {
      margin-top: 21px;
    }

    .accordion__link {
      color: #172e59;
    }
  }
}