/* Header_SP */

@use "../mixin" as *; //変数読み込み

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 50;
    background-color: #fff;

    .inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-right: 29px;
        padding-left: 29px;
    }

    .logo-area {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .logo-img {
        height: 45px;

    }

    .menu-area {
        display: flex;
        justify-content: end;
        align-items: center;
        flex-direction: row-reverse;
        gap: 35px;
    }

    .menu-list {
        font-size: $main-font-size;
    }

    .contact-button {
        padding-right: 30px;
        padding-left: 30px;
        background-color: $button-background-color;
        border-radius: 100px;
        font-size: $large3-font-size;
        color: #fff;
        font-weight: 700;
    }

    .contact-link {
        line-height: 50px;
    }

    .global-navigation__list {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 20px;
    }

    .global-navigation__link {
        color: $main-text-color;
        font-size: 17px;
        font-weight: 700;
    }
}